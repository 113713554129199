export const FIREBASE_API = JSON.parse(process.env.REACT_APP_FIREBASE_API);

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/loyalty/cards';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export const searchRoutes = [
    ['cards', '/loyalty/cards'],
    ['transactions', '/loyalty/transactions'],
    ['clients', '/loyalty/clients']
];

export default config;
