/**
 * axios setup to use mock service
 */

import axios from 'axios';
import firebase from 'firebase/compat/app';

const axiosServices = axios.create();

// Request interceptors for API calls
axiosServices.interceptors.request.use(async (request) => {
    const userIdToken = await firebase.auth().currentUser.getIdToken();
    const workspace = localStorage.getItem('workspace');

    request.baseURL = `${process.env.REACT_APP_API_URL}/${workspace}`;

    if (workspace && userIdToken) {
        request.headers.Authorization = `Bearer ${userIdToken}`;
    }

    return request;
});

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error?.response?.data || error?.message || error)
);

export default axiosServices;
