// third-party
import { createSlice } from '@reduxjs/toolkit';
// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    clients: []
};

const slice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        searchClientsSuccess(state, action) {
            state.clients = action.payload;
        },

        createClientSuccess(state, action) {
            console.log('Termine');
            state.clients = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function searchClients(search) {
    return async () => {
        try {
            const response = await axios.get(`/clients`, { params: { search } });
            dispatch(slice.actions.searchClientsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createClient(clientForm) {
    return async () => {
        try {
            await axios.post(`/clients`, clientForm);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateClient(clientForm, cid) {
    return async () => {
        try {
            await axios.put(`/clients/${cid}`, clientForm);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteClient(cid) {
    return async () => {
        try {
            await axios.delete(`/clients/${cid}`);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editClientActive(cid, isActive) {
    return async () => {
        try {
            await axios.put(`/clients/${cid}`, isActive);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
