// third-party
import { FormattedMessage } from 'react-intl';

import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
// import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
// import PercentIcon from '@mui/icons-material/Percent';
import ExtensionIcon from '@mui/icons-material/Extension';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import EventIcon from '@mui/icons-material/Event';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const loyalty = {
    id: 'loyalty',
    type: 'group',
    children: [
        {
            id: 'loyalty',
            title: <FormattedMessage id="loyalty" />,
            type: 'collapse',
            icon: LoyaltyIcon,
            breadcrumbs: false,
            children: [
                // {
                //     id: 'loyalty-settings',
                //     title: <FormattedMessage id="loyalty-settings" />,
                //     type: 'item',
                //     url: '/loyalty/settings',
                //     icon: SettingsIcon,
                //     breadcrumbs: false
                // },
                {
                    id: 'loyalty-cards',
                    title: <FormattedMessage id="loyalty-cards" />,
                    type: 'item',
                    url: '/loyalty/cards',
                    icon: CardMembershipIcon,
                    breadcrumbs: false
                },
                {
                    id: 'loyalty-transactions',
                    title: <FormattedMessage id="loyalty-transactions" />,
                    type: 'item',
                    url: '/loyalty/transactions',
                    icon: ReceiptIcon,
                    breadcrumbs: false
                },
                {
                    id: 'loyalty-clients',
                    title: <FormattedMessage id="loyalty-clients" />,
                    type: 'item',
                    url: '/loyalty/clients',
                    icon: PersonIcon,
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'promotions',
        //     title: <FormattedMessage id="promotions" />,
        //     type: 'item',
        //     url: '/promotions',
        //     icon: PercentIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'products',
            title: <FormattedMessage id="products" />,
            type: 'item',
            url: '/products',
            icon: ShoppingBagIcon,
            breadcrumbs: false
        },
        {
            id: 'events',
            title: <FormattedMessage id="events" />,
            type: 'item',
            url: '/events',
            icon: EventIcon,
            breadcrumbs: false
        },
        {
            id: 'plugins',
            title: <FormattedMessage id="plugins" />,
            type: 'item',
            url: '/plugins',
            icon: ExtensionIcon,
            breadcrumbs: false
        }
    ]
};

export default loyalty;
