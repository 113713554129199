// action - state management
import { LOGIN, LOGOUT, REGISTER, WORKSPACE, REFRESH_USER } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state, action) => {
    switch (action.type) {
        case REGISTER: {
            return {
                ...state,
                ...action.payload
            };
        }
        case LOGIN: {
            return {
                ...state,
                ...action.payload
            };
        }
        case LOGOUT: {
            localStorage.removeItem('workspace');
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                workspace: null
            };
        }
        case WORKSPACE: {
            return {
                ...state,
                ...action.payload
            };
        }
        case REFRESH_USER: {
            return {
                ...state
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
