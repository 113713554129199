// third-party
import { FormattedMessage } from 'react-intl';

// assets
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const account = {
    id: 'account',
    type: 'group',
    children: [
        {
            id: 'account',
            title: <FormattedMessage id="account" />,
            type: 'collapse',
            icon: ManageAccountsIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'users',
                    title: <FormattedMessage id="users" />,
                    type: 'item',
                    url: '/users',
                    icon: AccountCircleIcon,
                    breadcrumbs: false
                }
                // {
                //     id: 'account-settings',
                //     title: <FormattedMessage id="account-settings" />,
                //     type: 'item',
                //     url: '/account-settings',
                //     icon: SettingsIcon,
                //     breadcrumbs: false,
                // }
                // {
                //     id: 'account-invoices',
                //     title: <FormattedMessage id="account-invoices" />,
                //     type: 'item',
                //     url: '/account-invoices',
                //     icon: ReceiptIcon,
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default account;
