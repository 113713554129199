// third-party
import { createSlice } from '@reduxjs/toolkit';
import { uniq } from 'ramda';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    transactions: [],
    loading: false
};

const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        searchTransactionsSuccess(state, action) {
            state.transactions = action.payload;
        },

        searchTransactionsNextPageSuccess(state, action) {
            state.transactions = uniq([...state.transactions, ...action.payload]);
        },

        toggleLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function searchTransactions(search, from) {
    return async () => {
        try {
            dispatch(slice.actions.toggleLoading(true));
            const response = await axios.get(`/transactions`, { params: { search, from, limit: 20 } });
            dispatch(slice.actions.searchTransactionsSuccess(response.data));
            dispatch(slice.actions.toggleLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.toggleLoading(false));
        }
    };
}

export function searchNextPage(search, from) {
    return async () => {
        try {
            dispatch(slice.actions.toggleLoading(true));
            const response = await axios.get(`/transactions`, { params: { search, from, limit: 20 } });
            dispatch(slice.actions.searchTransactionsNextPageSuccess(response.data));
            dispatch(slice.actions.toggleLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.toggleLoading(false));
        }
    };
}
