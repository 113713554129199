// third-party
import { createSlice } from '@reduxjs/toolkit';
import { uniq } from 'ramda';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    card: {},
    cards: [],
    transactions: [],
    loading: false
};

const slice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getCardSuccess(state, action) {
            state.card = action.payload;
        },

        getCardTransactionsSuccess(state, action) {
            state.transactions = action.payload;
        },

        creditAdjustmentSuccess(state, action) {
            state.credit_adjustment = action.payload;
        },

        updateCardSuccess(state, action) {
            state.card_update = action.payload;
        },

        createNewCardSuccess(state, action) {
            state.card_create = action.payload;
        },

        searchCardsSuccess(state, action) {
            state.cards = action.payload;
        },

        searchCardsNextPageSuccess(state, action) {
            state.cards = uniq([...state.cards, ...action.payload]);
        },

        toggleLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCard(gid) {
    return async () => {
        try {
            dispatch(slice.actions.toggleLoading(true));
            const response = await axios.get(`/loyalty/cards/${gid}`);
            dispatch(slice.actions.getCardSuccess(response.data));
            dispatch(slice.actions.toggleLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.toggleLoading(false));
        }
    };
}

export function searchCards(search, from) {
    return async () => {
        try {
            dispatch(slice.actions.toggleLoading(true));
            const response = await axios.get(`/loyalty/cards/`, { params: { search, from, limit: 20 } });
            if (from) dispatch(slice.actions.searchCardsNextPageSuccess(response.data));
            if (!from) dispatch(slice.actions.searchCardsSuccess(response.data));
            dispatch(slice.actions.toggleLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.toggleLoading(false));
        }
    };
}

export function getCardTransactions(gid) {
    return async () => {
        try {
            const response = await axios.get(`/loyalty/cards/${gid}/transactions`, { params: { limit: 5 } });
            dispatch(slice.actions.getCardTransactionsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.getCardTransactionsSuccess([]));
        }
    };
}

export function creditAdjustment(gid, payload) {
    return async () => {
        try {
            const response = await axios.post(`/loyalty/cards/${gid}/transactions`, payload);
            dispatch(slice.actions.creditAdjustmentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error?.message || error));
        }
    };
}

export function updateCard(gid, payload) {
    return async () => {
        try {
            const response = await axios.put(`/loyalty/cards/${gid}`, payload);
            dispatch(slice.actions.updateCardSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error?.message || error));
        }
    };
}

export function createNewCard(payload) {
    return async () => {
        try {
            const response = await axios.post(`/loyalty/cards`, payload);
            dispatch(slice.actions.createNewCardSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error?.message || error));
        }
    };
}
