import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT, WORKSPACE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API } from 'config';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    isAuthorized: true,
    user: null,
    workspaces: [],
    workspace: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const localWorkspace = localStorage.getItem('workspace');
                    await user.getIdToken(true);
                    const { claims } = await user.getIdTokenResult();
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            isInitialized: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName || null,
                                photo: user.photoURL || null
                            },
                            workspaces: claims?.workspaces || [],
                            workspace: claims?.workspaces?.includes(localWorkspace) ? localWorkspace : null
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        []
    );

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => {
        firebase.auth().signOut();
        dispatch({
            type: LOGOUT
        });
    };

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = (data) => firebase.auth().currentUser.updateProfile(data);

    const setSelectedWorkspace = (workspace) => {
        localStorage.setItem('workspace', workspace);
        dispatch({
            type: WORKSPACE,
            payload: {
                workspace
            }
        });
    };

    const userTokenId = firebase?.auth()?.currentUser?.getIdToken();

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => true, // change true to object or function {}
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                setSelectedWorkspace,
                userTokenId
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
