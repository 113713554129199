// material-ui
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { dispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { createEvent, deleteEvent, editEventActive, searchEvents, updateEvent } from 'store/slices/events';
// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| TABLE - DATA TABLE ||============================== //

export default function Events() {
    const { events } = useSelector((state) => state.events);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [event, setEvent] = React.useState(null);
    const [createName, setCreateName] = React.useState('');
    const [createDescription, setCreateDescription] = React.useState('');
    const [createAmount, setCreateAmount] = React.useState(0);

    const handleClose = () => {
        setOpen(false);
        setCreateDescription('');
        setCreateName('');
        setCreateAmount('');
    };

    const getEventsFn = async () => {
        setLoading(true);
        await dispatch(searchEvents());
        setLoading(false);
    };

    const createEventFn = async (e) => {
        e.preventDefault();
        if (event) {
            await dispatch(
                updateEvent(
                    {
                        name: createName,
                        description: createDescription,
                        amount: Number(createAmount),
                        active: true
                    },
                    event.eid
                )
            );
        } else {
            await dispatch(
                createEvent({
                    name: createName,
                    description: createDescription,
                    amount: Number(createAmount),
                    active: true
                })
            );
        }
        setCreateDescription('');
        setCreateName('');
        setCreateAmount('');
        setOpen(false);
        setEvent(null);
        getEventsFn();
    };

    const editEventFn = async (event) => {
        setCreateName(event.name);
        setCreateDescription(event.description);
        setCreateAmount(event.amount);
        setEvent(event);
        setOpen(true);
    };

    const deleteEventFn = async (event) => {
        await dispatch(deleteEvent(event.eid));
        getEventsFn();
    };

    const onChangeActive = async (e, event) => {
        await dispatch(editEventActive(event.eid, { active: e.target.checked }));
        getEventsFn();
    };

    useEffect(() => {
        getEventsFn();
    }, []);

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Events</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                            <Grid item>
                                <Button variant="outlined" onClick={() => setOpen(true)}>
                                    New Event
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading ? (
                        <TableBody>
                            {events?.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell align="right">$ {parseFloat(row.amount).toFixed(2)}</TableCell>
                                    <TableCell>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={row.active}
                                                        onChange={(e) => {
                                                            onChangeActive(e, row);
                                                        }}
                                                    />
                                                }
                                                label={row.active ? 'Active' : 'Inactive'}
                                            />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton color="primary" component="span" onClick={() => editEventFn(row)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="error" component="span" onClick={() => deleteEventFn(row)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={10} rowsPerPage={10} page={0} />
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={(e) => createEventFn(e)}>
                    <DialogTitle id="alert-dialog-title">Create event</DialogTitle>
                    <DialogContent>
                        <Grid container maxWidth="400px">
                            <Grid item xs={12}>
                                <TextField
                                    label="Nombre"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={createName}
                                    onChange={(e) => {
                                        setCreateName(e.target.value);
                                    }}
                                />

                                <TextField
                                    label="Descripcion"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={createDescription}
                                    onChange={(e) => {
                                        setCreateDescription(e.target.value);
                                    }}
                                />

                                <TextField
                                    label="Monto"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    type="number"
                                    value={createAmount}
                                    onChange={(e) => {
                                        setCreateAmount(e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <DialogActions sx={{ padding: '20px 0px 0px 0px' }}>
                            <Button variant="contained" onClick={() => handleClose(false)} color="error">
                                Cancelar
                            </Button>
                            <Button variant="contained" type="submit" autoFocus>
                                {event ? 'Actualizar' : 'Crear'}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </MainCard>
    );
}
