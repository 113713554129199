import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, isAuthorized, workspace } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn && !isAuthorized) navigate('authorize', { replace: true });
        if (isLoggedIn && isAuthorized && !workspace) navigate('workspace', { replace: true });

        if (isLoggedIn && isAuthorized && workspace) navigate(localStorage.getItem('originalPath') || DASHBOARD_PATH, { replace: true });
    }, [isAuthorized, isLoggedIn, location.pathname, navigate, workspace]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
