// third-party
import { createSlice } from '@reduxjs/toolkit';
// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    events: []
};

const slice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        searchEventsSuccess(state, action) {
            state.events = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function searchEvents(search) {
    return async () => {
        try {
            const response = await axios.get(`/events`, { params: { search } });
            dispatch(slice.actions.searchEventsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createEvent(eventForm) {
    return async () => {
        try {
            await axios.post(`/events`, eventForm);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateEvent(eventForm, cid) {
    return async () => {
        try {
            await axios.put(`/events/${cid}`, eventForm);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteEvent(cid) {
    return async () => {
        try {
            await axios.delete(`/events/${cid}`);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editEventActive(cid, isActive) {
    return async () => {
        try {
            await axios.put(`/events/${cid}`, isActive);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
