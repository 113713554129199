import account from './account';
// import home from './home';
import loyalty from './loyalty';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // eslint-disable-next-line prettier/prettier
    items: [
        // home,
        loyalty,
        account
    ]
};

export default menuItems;
