import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// login routing
const Error = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| AUTH ROUTING ||============================== //

const MaintenanceRoutes = {
    path: '*',
    element: <Error />
};

export default MaintenanceRoutes;
