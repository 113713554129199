// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    users: [],
    apiKey: ''
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getUsersListSuccess(state, action) {
            state.users = action.payload;
        },

        createApiKeySuccess(state, action) {
            state.apiKey = action.payload.apiKey;
        },

        cleanApiKeyState(state) {
            state.apiKey = '';
        },

        createInvitesSuccess(state, action) {
            state.invites = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsersList() {
    return async () => {
        try {
            const response = await axios.get('/users');
            dispatch(slice.actions.getUsersListSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createApiKey(apiKeyName) {
    return async () => {
        try {
            const response = await axios.post('/auth/api-keys', { apiKeyName });
            dispatch(slice.actions.createApiKeySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function cleanApiKey() {
    return async () => {
        try {
            dispatch(slice.actions.cleanApiKeyState());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createInvite(email) {
    return async () => {
        try {
            const response = await axios.post('/invites', { email });
            dispatch(slice.actions.createInvitesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeUser(uid) {
    return async () => {
        try {
            const response = await axios.delete(`/users/${uid}`);
            dispatch(slice.actions.deleteUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
