// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import calendarReducer from './slices/calendar';
import cardsReducer from './slices/cards';
import cartReducer from './slices/cart';
import chatReducer from './slices/chat';
import clientsReducer from './slices/clients';
import profileReducer from './slices/profile';
import contactReducer from './slices/contact';
import customerReducer from './slices/customer';
import eventsReducer from './slices/events';
import kanbanReducer from './slices/kanban';
import mailReducer from './slices/mail';
import menuReducer from './slices/menu';
import productReducer from './slices/product';
import productsReducer from './slices/products';
// project imports
import snackbarReducer from './slices/snackbar';
import transactionsReducer from './slices/transactions';
import userReducer from './slices/user';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contact: contactReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    menu: menuReducer,
    cards: cardsReducer,
    products: productsReducer,
    transactions: transactionsReducer,
    clients: clientsReducer,
    profile: profileReducer,
    events: eventsReducer
});

export default reducer;
