// third-party
import { createSlice } from '@reduxjs/toolkit';
// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    products: []
};

const slice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        searchProductsSuccess(state, action) {
            state.products = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function searchProducts(search) {
    return async () => {
        try {
            const response = await axios.get(`/products`, { params: { search } });
            dispatch(slice.actions.searchProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProduct(body) {
    return async () => {
        try {
            await axios.post(`/products`, body);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProduct(pid, body) {
    return async () => {
        try {
            await axios.put(`/products/${pid}`, body);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteProduct(pid) {
    return async () => {
        try {
            await axios.delete(`/products/${pid}`);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editProductActive(pid, isActive) {
    return async () => {
        try {
            await axios.put(`/products/${pid}`, isActive);
            dispatch();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
