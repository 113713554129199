// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Events from 'views/events';

// sample page routing
const Transactions = Loadable(lazy(() => import('views/loyalty/transactions')));
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const LoyaltyCards = Loadable(lazy(() => import('views/loyalty/loyaltyCards')));
const CardDetail = Loadable(lazy(() => import('views/loyalty/loyaltyCards/CardDetail')));
const UsersPage = Loadable(lazy(() => import('views/users')));
const ProductsPage = Loadable(lazy(() => import('views/products')));
const PromotionsPage = Loadable(lazy(() => import('views/promotions')));
const NewPromotion = Loadable(lazy(() => import('views/promotions/NewPromotion')));
const Clients = Loadable(lazy(() => import('views/clients')));
const PluginsPage = Loadable(lazy(() => import('views/plugins')));
const ProfilePage = Loadable(lazy(() => import('views/users/account-profile/Profile2')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <LoyaltyCards />
        },
        {
            path: '/loyalty/cards',
            element: <LoyaltyCards />
        },
        {
            path: '/loyalty/cards/:gid',
            element: <CardDetail />
        },
        {
            path: '/loyalty/transactions',
            element: <Transactions />
        },
        {
            path: '/users',
            element: <UsersPage />
        },
        {
            path: '/products',
            element: <ProductsPage />
        },
        {
            path: '/promotions',
            element: <PromotionsPage />
        },
        {
            path: '/promotions/new-promotion',
            element: <NewPromotion />
        },
        {
            path: '/loyalty/clients',
            element: <Clients />
        },
        {
            path: '/plugins',
            element: <PluginsPage />
        },
        {
            path: '/account-settings',
            element: <ProfilePage />
        },
        {
            path: '/events',
            element: <Events />
        }
    ]
};

export default MainRoutes;
