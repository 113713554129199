import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isAuthorized, workspace, isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) navigate('login', { replace: true });
        if (isLoggedIn && !isAuthorized) navigate('authorize', { replace: true });
        if (isLoggedIn && isAuthorized && !workspace) navigate('workspace', { replace: true });

        localStorage.setItem('originalPath', location.pathname);
    }, [isAuthorized, location.pathname, navigate, isLoggedIn, workspace]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
